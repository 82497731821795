












































import {
  defineComponent,
  useFetch,
  ref,
  computed,
  useContext
} from '@nuxtjs/composition-api';
import { useBlog } from '@wemade-vsf/blog';
import { useImage } from '@wemade-vsf/composables';
import type { BlogPost as BlogPostInterface } from '@wemade-vsf/magento-api';
import BlogPost from 'components/blog/BlogPost.vue';
import { SfHeading, SfButton } from '@storefront-ui/vue';
export default defineComponent({
  name: 'BlogPostList',
  props: {
    title: {
      type: String,
      default: ''
    },
    showLink: {
      type: Boolean,
      default: false
    },
    linkText: {
      type: String,
      default: ''
    },
    linkUrl: {
      type: String,
      default: ''
    },
    numPosts: {
      type: Number,
      default: 3
    },
    type: {
      type: String,
      default: 'new'
    },
    topicId: {
      type: Number,
      default: 0
    },
    sku: {
      type: String,
      default: ''
    },
    linkDisplay: {
      type: String,
      default: 'link'
    },
    postLinkDisplay: {
      type: String,
      default: 'link'
    },
    link: {
      type: String,
      default: ''
    },
    level: {
      type: Number,
      default: 2
    }
  },
  components: {
    BlogPost,
    SfHeading,
    SfButton
  },
  setup (props) {
    const { loading, getPostList } = useBlog();
    const posts = ref<BlogPostInterface[]>([])
      const { localePath } = useContext()
    useFetch(async () => {
      let query = {
        pageSize: props.numPosts,
        currentPage: 1
      }
      if (props.type === 'topic' && props.topicId) {
        query['action'] = 'get_post_by_topic'
        query['topicId'] = props.topicId
      }
      const result = await getPostList(query)
      posts.value = result?.items || []
    })
    const { getMagentoImage } = useImage();
    const hasLink = computed(() => !!props.linkUrl && !!props.linkText && !!props.showLink)
    const isExternal = computed(() => hasLink.value && props.linkUrl.startsWith('http'))
    const ctaLink = computed(() => {
      if (hasLink.value) {
        return isExternal ? props.linkUrl : localePath(props.linkUrl)
      }
      return ''
    })
    return {
      loading,
      getMagentoImage,
      posts,
      hasLink,
      ctaLink
    }
  }
})
